import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  List,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';

import { useKdey, useKdeys } from '../queries/kdeys';

function KdeyInfo() {
  const { kdeyId } = useParams();

  const kdey = useKdey(kdeyId);

  const mapRef = useRef();
  const [mapLoaded, setMapLoaded] = useState(false);
  const KdeyMap = mapRef.current;

  useEffect(() => {
    mapRef.current = require('./KdeyMap').default;
    setMapLoaded(true);
  }, []);

  return (
    <Segment
      padded="very"
      basic
      loading={kdey.isLoading}
      placeholder={kdey.isLoading}
    >
      <Container style={{ fontSize: '1.14285714rem' }}>
        {kdey.isError && (
          <Message error>
            <Message.Header>Σφάλμα</Message.Header>
            <Message.Content>
              Πρέκυψε κάποιο σφάλμα, πατήστε <Link to="/">εδώ</Link> για να
              κατευθυνθείτε στην αρχική
            </Message.Content>
          </Message>
        )}
        {kdey.data && (
          <>
            <Header textAlign="center" as="h2">
              {kdey.data?.name}
            </Header>
            <Divider hidden />
            <Grid>
              <Grid.Column mobile={16} computer={5} tablet={5}>
                <Image
                  className="kdey-image"
                  wrapped
                  alt={kdey.data?.name}
                  src={kdey.data?.url || '/placeholder.jpg'}
                />
              </Grid.Column>
              <Grid.Column mobile={16} computer={6} tablet={6}>
                <List className="padded" divided relaxed>
                  <List.Item>
                    <List.Header>Ωράριο</List.Header>
                    <List.Content>
                      Δε-Τρ-Τε-Πε-Πα: 08:00-15:00 <br /> Σα: 09:00-13:00
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Header>Τηλέφωνο επικοινωνίας</List.Header>
                    <List.Content>{kdey.data?.telephone}</List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Header>Διεύθυνση</List.Header>
                    <List.Content>{kdey.data?.address}</List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              {kdey.data.lat && kdey.data.lng && (
                <Grid.Column mobile={16} computer={5} tablet={5}>
                  {mapLoaded && <KdeyMap kdey={kdey.data} />}
                </Grid.Column>
              )}
            </Grid>
          </>
        )}
      </Container>
      <Divider section hidden />
    </Segment>
  );
}

function KdeyList() {
  const match = useRouteMatch();

  const kdeys = useKdeys();

  if (kdeys.isLoading) {
    return <Loader inline="centered" active style={{ margin: '16px 0' }} />;
  }
  if (kdeys.isError) {
    return <Message error content="Προέκυψε κάποιο σφάλμα" />;
  }

  return (
    <div style={{ marginBottom: '16px' }}>
      <List relaxed bulleted className="decorated green">
        {kdeys.data?.map(({ id, name }) => (
          <List.Item>
            <Link to={`${match.url}/kdey/${id}`}>{name}</Link>
          </List.Item>
        ))}
      </List>
      <Divider />
    </div>
  );
}

function ReuseCentersIntro() {
  return (
    <Segment padded="very" basic attached className="colored lightgreen">
      <Container text>
        <KdeyList />
        <p>
          Η ΔΙΑΔΥΜΑ έχει προσθέσει στις δράσεις της, τη λειτουργία των Κέντρων
          Δημιουργικής Επαναχρησιμοποίησης Υλικών (ΚΔΕΥ), στις πόλεις της
          Καστοριάς και της Φλώρινας, σύντομα θα ξεκινήσει και στα Γρεβενά (υπό
          κατασκευή) και μελλοντικά στις πόλεις Κοζάνη και Πτολεμαΐδα. Τα ΚΔΕΥ
          αποτελούν μια απλή κτιριακή υποδομή για την ταξινόμηση, την εν δυνάμει
          επιδιόρθωση και την αποθήκευση των υλικών, τα οποία θα διαθέτουν και
          χώρους έκθεσης των υλικών. Η λειτουργία τους θα γίνεται από τη ΔΙΑΔΥΜΑ
          Α.Ε. με τη συνεργασία των ομώνυμων Δήμων, ενώ υπάρχει η δυνατότητα
          υποστήριξης και από φορείς της κοινωνικής οικονομίας και εθελοντικές
          ομάδες.
        </p>
        <Header as="h3">Στόχοι δημιουργίας των ΚΔΕΥ:</Header>
        <Divider hidden />
        <List relaxed bulleted className="decorated orange">
          <List.Item>
            <span>
              Να προωθηθεί σταδιακά και να τεθούν σε εφαρμογή οι δράσεις
              Πρόληψης Δημιουργίας Αποβλήτων
            </span>
          </List.Item>
          <List.Item>
            <span>
              Να ενημερώσει και να ευαισθητοποιήσει του πολίτες (δημότες
              εξυπηρετούμενης περιοχής από το ΚΔΕΥ) για την ανάγκη μείωσης της
              παραγωγής αποβλήτων
            </span>
          </List.Item>
          <List.Item>
            <span>
              Να βελτιώσει την καταναλωτική συμπεριφορά, προωθώντας τη βιώσιμη
              κατανάλωση
            </span>
          </List.Item>
          <List.Item>
            <span>
              Να αυξήσει τη διάρκεια ζωής των αγαθών, υλικών και προϊόντων, μέσω
              της επαναχρησιμοποίησης
            </span>
          </List.Item>
          <List.Item>
            <span>
              Να τεθούν οι βάσεις για την σταδιακή εφαρμογή προγραμμάτων
              ‘Πληρώνω Όσο Πετάω’ για τη διαχείριση των αποβλήτων
            </span>
          </List.Item>
        </List>
        <p>
          Στις εγκαταστάσεις των ΚΔΕΥ οργανώνονται διάφορες εκδηλώσεις για
          ανταλλαγή υλικών, θεματικές γιορτές/παζάρια υλικών προς
          επαναχρησιμοποίηση και άλλες τέτοιες δράσεις που θα συνδράμουν
          ουσιαστικά στην αλλαγή της νοοτροπίας και της αντίληψης των πολιτών
          περί της διαχείρισης των αποβλήτων και της χρησιμότητας των υλικών.
        </p>
        <p>
          Οποιοσδήποτε ενδιαφερόμενος το επιθυμεί (σχολείο, εκπαιδευτική δομή ή
          φορέας, δημόσια υπηρεσία, εθελοντική ομάδα που δραστηριοποιείται στα
          περιβαλλοντικά ζητήματα, κλπ.) μπορεί να απευθυνθεί στη{' '}
          <a href="https://diadyma.gr">ΔΙΑΔΥΜΑ</a> είτε για επίσκεψη στο χώρο, είτε για μία από
          κοινού εκδήλωση.
        </p>
        <Header as="h3" size="small">
          Ο κάθε πολίτης μπορεί είτε να παραδίδει αντικείμενα που δεν είναι
          απόβλητα και δεν τα χρειάζεται, είτε να αποκτά υλικά από τους χώρους
          των ΚΔΕΥ, χωρίς κόστος.
        </Header>
        <p>
          Τα υλικά που συγκεντρώνονται προς επαναχρησιμοποίηση περιλαμβάνουν:
        </p>
        <List bulleted>
          <List.Item>Βιβλία – Χαρτικά – Είδη γραφείου – Γραφική ύλη</List.Item>
          <List.Item>
            Οικιακά σκεύη (πιάτα, ποτήρια, μαγειρικά σκεύη, κλπ)
          </List.Item>
          <List.Item>Είδη οικιακής χρήσης-Διακοσμητικά</List.Item>
          <List.Item>
            Είδη προσωπικής περιποίησης (σεσουάρ, ξυριστικές μηχανές, κλπ)
          </List.Item>
          <List.Item>Εργαλεία (παντός τύπων)</List.Item>
          <List.Item>
            Ηλεκτρικές & Ηλεκτρονικές Συσκευές (ψυγεία, πλυντήρια, Η/Υ,
            μικροσυσκευές, κλπ)
          </List.Item>
          <List.Item>
            Είδη ένδυσης – Υπόδησης – Αξεσουάρ – Είδη ταξιδιού
          </List.Item>
          <List.Item>Λευκά ειδή (πετσέτες, σεντόνια, κλπ)</List.Item>
          <List.Item>Μοκέτες – χαλιά</List.Item>
          <List.Item>
            Είδη επίπλωσης (καρέκλες, τραπέζια, καναπέδες, σύνθετα, κλπ)
          </List.Item>
          <List.Item>Είδη χόμπι-Δραστηριότητες (διάφορα είδη)</List.Item>
          <List.Item>Παιχνίδια (διάφορα είδη)</List.Item>
          <List.Item>
            Παιδικά είδη (είδη βρεφοανάπτυξης, παιδικά καθίσματα, κλπ)
          </List.Item>
          <List.Item>CD/DVD (ταινιών, μουσικής, παιχνιδιών, κλπ)</List.Item>
          <List.Item>
            Είδη για κατοικίδια ζώα (σπίτια, κλουβιά, είδη περιποίησης, κλπ)
          </List.Item>
          <List.Item>
            Εποχιακά είδη (χριστουγεννιάτικα, πασχαλιάτικα, καλοκαιρινά, κλπ)
          </List.Item>
        </List>
        <p>Τα υλικά θα πρέπει:</p>
        <div className="padded-left">
          <List relaxed className="decorated green check icon">
            <List.Item>
              <span>Να είναι σε καλή κατάσταση/λειτουργικά</span>
            </List.Item>
            <List.Item>
              <span>
                Να μην έχουν φθαρεί σε βαθμό που δεν μπορούν να
                επαναχρησιμοποιηθούν
              </span>
            </List.Item>
            <List.Item>
              <span>Οι ηλεκτρικές συσκευές να λειτουργούν</span>
            </List.Item>
          </List>
        </div>
      </Container>
      <Divider section hidden />
    </Segment>
  );
}

export default function ReuseCenters() {
  const pageTitle = 'Κέντρα Δημιουργικής Επαναχρησιμοποίησης Υλικών';
  const match = useRouteMatch();
  return (
    <>
      <Helmet title={pageTitle} />

      <Container>
        <Header
          textAlign="center"
          as="h1"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
      </Container>
      <Switch>
        <Route exact path={match.path}>
          <ReuseCentersIntro />
        </Route>
        <Route exact path={`${match.path}/kdey/:kdeyId`}>
          <KdeyInfo />
        </Route>
      </Switch>
    </>
  );
}
